<template>
  <b-card>
    <b-row class="d-flex justify-content-end align-items-center mb-1">
      <atualizar-dctfweb
        @atualizar-grid="atualizarGrid()"
      />
      <v-select
        id="baixaSelect"
        :ref="`SelectFiltroDocs`"
        v-model="filtroTipoDownload"
        v-b-tooltip.hover
        :options="opcoesBaixaEmLote"
        :clearable="true"
        label="nome"
        class="baixaSelect"
        placeholder="Baixar todas Declarações DctfWeb"
        style="font-size: 11.5px;padding: .40rem; width:270px;"
        @input="baixarEmLote"
      />
    </b-row>
    <declaracao-list
      ref="dctfWebList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :mostrar-acoes-padrao="true"
      :filtros-rapidos="filtrosRapidos"
      :tem-filtro-extra="true"
    >
      <template
        slot="filtro-extra"
      >
        <b-row>
          <b-col cols="4">
            <b-form-select
              v-model="mensal"
              @input="mudarFiltroData($event? filtroCustomData : filtroCustomAno )"
            >
              <b-form-select-option :value="true">
                Mensal
              </b-form-select-option>
              <b-form-select-option :value="false">
                Anual
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="8">
            <b-form-input
              v-if="mensal"
              v-model="filtroCustomData"
              type="month"
              @change="mudarFiltroData($event)"
            />
            <b-select
              v-else
              v-model="filtroCustomAno"
              name="filtroSelecao"
              class="search-items"
              :options="['2025', '2024', '2023', '2022', '2021', '2020', '2019', '2018']"
              @input="mudarFiltroData($event)"
            />
          </b-col>
        </b-row>
      </template>
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'situacao'"
          :variant="getColorTag(props.row[props.column.field])"
        >
          <div :style="props.row[props.column.field].length > 26 ? 'width: 180px; white-space: normal;' : 'max-width: 180px;'">
            {{ props.row[props.column.field] }}
          </div>
        </b-badge>
        <div v-else-if="props.column.field === 'transmissao'">
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'situacao'"
          :variant="getColorTag(props.row[props.column.field])"
        >
          <div :style="props.row[props.column.field].length > 26 ? 'width: 180px; white-space: normal;' : 'max-width: 180px;'">
            {{ props.row[props.column.field] }}
          </div>
        </b-badge>
        <div v-else-if="props.column.field === 'transmissao'">
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          @atualizar-grid="atualizarGrid()"
        />
      </template>
    </declaracao-list>
  </b-card>
</template>

<style lang="scss">

.col-valores{
  white-space: nowrap;
}
[dir] #baixaSelect .vs__dropdown-toggle{
  border-color: var(--primary) !important;
}
</style>

<script>
import moment from 'moment'
import { getUserInfo } from '@/auth/utils'
import vSelect from 'vue-select'
import api from './shared/services/api'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  name: 'DeclaracaoDctfWebView',
  components: {
    vSelect,
    DeclaracaoList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    AtualizarDctfweb: () => import('./shared/components/atualizacao-dctfweb/AtualizacaoDctfweb.vue'),
  },
  data() {
    return {
      botoesOption: null,
      linha: null,
      mensal: true,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social',
        acaoClass: 'col-td-acao-3',
        disabledCheckbox: true,
        colunas: [
          { label: 'Razão social', field: 'razaoSocial', tdClass: 'col-tamanho-cnpj' },
          { label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-tamanho-cnpj' },
          { label: 'Período apuração', field: 'periodoApuracao' },
          { label: 'Transmissão', field: 'transmissao', type: 'custom' },
          { label: 'Categoria', field: 'categoria' },
          { label: 'Origem', field: 'origem' },
          { label: 'Tipo', field: 'tipo' },
          { label: 'Situação', field: 'situacao', type: 'custom' }, // regular ou irregular
          { label: 'Débito apurado', field: 'debitoApurado', tdClass: 'col-valores' },
          { label: 'Saldo a pagar', field: 'saldoPagar', tdClass: 'col-valores' },
          { label: 'Consultado em', field: 'consultadoEm', tdClass: 'col-valores' },
        ],
      },
      filtroTipoDownload: null,
      filtroCustomData: '',
      filtroCustomAno: '',
      filtros: [],
      filtrosBase: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Ativa',
          colunas: ['Situacao'],
          label: 'Ativa',
          valor: '2',
        },
        {
          id: 'Em Andamento',
          colunas: ['Situacao'],
          label: 'Em Andamento',
          valor: '1',
        },
        {
          id: 'Retificada',
          colunas: ['Situacao'],
          label: 'Retificada',
          valor: '3',
        },
        {
          id: 'Excluida',
          colunas: ['Situacao'],
          label: 'Excluida',
          valor: '4',
        },
        {
          id: 'Indevida',
          colunas: ['Situacao'],
          label: 'Indevida',
          valor: '5',
        }],
      criando: true,
      aplicando: false,
      filtroAtual: {},
      opcoesBaixaEmLote: ['DARF', 'Recibo', 'Ambos'],
    }
  },
  created() {
    const mes = moment().subtract(1, 'month').format('yyyy-MM')
    this.mensal = !this.$route.query.filtroAno

    if (this.mensal) {
      this.filtroCustomData = this.$route.query.filtroMes ? this.$route.query.filtroMes : mes
    } else {
      this.filtroCustomAno = this.$route.query.filtroAno
    }

    if (!this.filtroCustomData) {
      this.filtroCustomData = mes
    }

    this.filtros = [...this.filtrosBase, { valor: this.mensal ? this.filtroCustomData : `${this.filtroCustomAno}-01`, colunas: [this.mensal ? 'Competencia' : 'CompetenciaAnual'] }]
    this.visualizacao.evento = this.carregar
  },
  methods: {
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    getColorTag(situacao) {
      if (situacao === 'EmAndamento') {
        return 'light-primary'
      }
      if (situacao === 'Ativa') {
        return 'light-success'
      }
      if (situacao === 'Retificada') {
        return 'light-warning'
      }
      if (situacao === 'Excluida') {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    baixarEmLote(evt) {
      const data = new Date()
      const nome = `DctfWeb-${getUserInfo().empresa}-${data.getDate()}-${data.getMonth() + 1}-${data.getFullYear()}`
      api.baixaEmLote(this.filtroAtual, nome, evt)
      this.filtroTipoDownload = null
    },
    mudarFiltroData(data) {
      const dataFinal = this.mensal ? data : `${data}-01`
      const coluna = this.mensal ? ['Competencia'] : ['CompetenciaAnual']
      const filtroData = { valor: dataFinal, colunas: coluna }

      const index = this.$refs.dctfWebList.enviarServidor.filtros.findIndex(where => where.colunas.length === 1 && where.colunas.some(any => filtroData === null || any === (this.mensal ? 'CompetenciaAnual' : 'Competencia')))

      if (index >= 0) {
        this.$refs.dctfWebList.enviarServidor.filtros.splice(index, 1)
      }

      const filtronew = [...this.filtrosBase, filtroData]
      this.filtros = filtronew
      this.$refs.dctfWebList.filtroImutavel = [...filtronew]
      this.$refs.dctfWebList.aplicarFiltroRapido(filtroData, false)
    },
    atualizarGrid() {
      this.$refs.dctfWebList.buscar()
    },
  },
}
</script>
